import { Survey, SurveyItem, SurveySegment } from "models";
import { UuidTool } from "uuid-tool";

export function buildNewSurvey(
  changedItems: Record<string, number>,
  survey: Survey
) {
  // loop over every segment
  const { segments, items } = survey.segments.reduce<{
    items: SurveyItem[];
    segments: SurveySegment[];
  }>(
    (acc, segment) => {
      const newSegmentId = UuidTool.newUuid();
      // create a new segment with the new id
      const newSegment = new SurveySegment({
        ...segment,
        id: newSegmentId,
      });
      const segmentItems = survey.items.filter(
        (item) => item.segmentId === segment.id
      );
      // find every item associated with the segment
      const newItems = segmentItems.map((item) => {
        const changedKey = `${segment.order.number}:${item.roomId}:${item.id}`;
        // calculate changes to going/notGoing
        const newGoing = (item.going ?? 0) + (changedItems[changedKey] ?? 0);
        const newNotGoing =
          (item.notGoing ?? 0) - (changedItems[changedKey] ?? 0);
        // create new item associated with the new segment
        return new SurveyItem({
          ...item,
          id: UuidTool.newUuid(),
          going: newGoing < 0 ? 0 : newGoing,
          notGoing: newNotGoing < 0 ? 0 : newNotGoing,
          segmentId: newSegmentId,
        });
      });
      return {
        segments: acc.segments.concat([newSegment]),
        items: acc.items.concat(newItems),
      };
    },
    {
      segments: [],
      items: [],
    }
  );

  const newSurvey = new Survey({
    items: items,
    surveyType: "VIRTUAL",
    isComplete: survey.isComplete,
    service: survey.service,
    notes: survey.notes,
    segments,
  });

  return newSurvey;
}
