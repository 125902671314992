import { useOrder } from "store";
import {
  useSurvey,
  useChangedItemsAtom,
} from "components/pages/BelongingsPage/tools/surveyAtom";
import type { Survey } from "models/Survey";
import { ShipmentWeightBreakdowns } from "types";

const round = (n: number) => Math.round(n);

type SurveyCalculation = {
  hasSurvey: boolean;
  estimatedWeight: number;
  estimatedWeightPercentage: number;
  estimatedWeightBreakDown: ShipmentWeightBreakdowns;
  overage: number;
  hasOverage: boolean;
};

const defaultCalculation: SurveyCalculation = {
  hasSurvey: false,
  estimatedWeight: 0,
  estimatedWeightPercentage: 0,
  estimatedWeightBreakDown: {},
  overage: 0,
  hasOverage: false,
};

const hasChangedItems = (items: Record<string, number>) => {
  const changedItemsVals = Object.values(items).filter((item) => item !== 0);
  return changedItemsVals.length !== 0;
};

type CalculateParams = {
  survey?: Survey;
  entitlementAmount: number;
  changedItems: Record<string, number>;
};
export function calculateSurveyStats({
  survey,
  entitlementAmount,
  changedItems,
}: CalculateParams) {
  if (!survey) {
    return defaultCalculation;
  }
  const estimatedWeightBreakDown = survey.getEstimatedWeightBreakDown(changedItems);

  const weight = hasChangedItems(changedItems)
    ? Object.values(estimatedWeightBreakDown).reduce(
        (sum, {weight}) => sum + weight,
        0
      )
    : survey.estimatedWeight;

  const overage = weight - entitlementAmount;
  const hasOverage = overage > 0;
  const estimatedWeightPercentage = Math.ceil(
    (weight / entitlementAmount) * 100
  );

  return {
    hasSurvey: true,
    estimatedWeight: round(weight),
    estimatedWeightPercentage,
    estimatedWeightBreakDown,
    overage: round(overage),
    hasOverage,
  };
}

export function useSurveyCalculations(
  /**
   * If true, will factor in the changed items from the weight wizard
   */
  includeChanges?: boolean
): SurveyCalculation {
  const order = useOrder();
  const survey = useSurvey();
  const [{ changedItems }] = useChangedItemsAtom();

  const { hasOverage, estimatedWeight, ...rest } = calculateSurveyStats({
    survey,
    entitlementAmount: order?.entitlement?.totalWeight,
    changedItems: includeChanges ? changedItems : {},
  });
  return {
    ...rest,
    hasOverage,
    estimatedWeight,
  };
}
